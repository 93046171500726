import React, { useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";

import { ReactComponent as BackButton } from "../assets/svg/back.svg";
import styles from "./Menu.module.css";
import classNames from "classnames/bind";

function Header(props) {
  const navigate = useNavigate();
  const {location} = props;
  const menuInputRef = useRef(null);
  let cx = classNames.bind(styles);
  
  const handleMenuInput = () => {
    document.body.style.overflow = document.body.style.overflow ? "" : "hidden";
  };
  const handleBackButton = () => {
    navigate(-1);
  };

  const labels = ["Accueil", "Réalisations", "À propos", "Contact"];

  useEffect(() => {
    menuInputRef.current.checked = false;
    window.scrollTo(0, 0);
    document.body.style.overflow = "";
  },[location.pathname]);

  return (
    <div className={styles.container}>
      {location.pathname !== "/" && (
        <button className={styles["btn-back"]} onClick={handleBackButton}>
          <BackButton />
        </button>
      )}
      <nav className={styles.nav}>
        <div className={cx({"hamburger-button": true, "highlight": (location.pathname === "/")})}>
          <input id="menu-input" type="checkbox" onClick={handleMenuInput} ref={menuInputRef} />
          <div className={styles["hamburger-icon"]}>
            <hr />
            <hr />
            <hr />
          </div>
          <div className={styles.wrapper}>
            <div className={styles["btn-close"]}></div>
            <ul>
              <li>
                {location.pathname !== "/" ? (
                  <Link to="/" className={styles.link}>
                    {labels[0]}
                  </Link>
                ) : (
                  labels[0]
                )}
              </li>
              <li>
                {location.pathname !== "/art" ? (
                  <Link to="/art" className={styles.link}>
                    {labels[1]}
                  </Link>
                ) : (
                  labels[1]
                )}
              </li>
              <li>
                {location.pathname !== "/about" ? (
                  <Link to="/about" className={styles.link}>
                    {labels[2]}
                  </Link>
                ) : (
                  labels[2]
                )}
              </li>
              <li>
                {location.pathname !== "/contact" ? (
                  <Link to="/contact" className={styles.link}>
                    {labels[3]}
                  </Link>
                ) : (
                  labels[3]
                )}
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Header;
