import React, { Component } from 'react';
import Player from "@vimeo/player";
//import styles from "./VideoEmbed.module.css";

export default class VideoEmbed extends Component {

    constructor(props, context) {
        super(props, context);

        this.setRef = this.setRef.bind(this);

        this.vid = props.vid;

		this.options = {
	        id: props.vid,
	        autoplay: false,
	        responsive: true,
	        dnt: true,
            playsinline: false,
            portrait: false,
            byline: false,
            title: false
    	};

        this.triggerReady = props.triggerReady;
        
        this.state = {
            isPlaying: false
        }

        this.play = this.play.bind(this);
        this.pause = this.pause.bind(this);
    }

    setRef(el){
    	this.htmlEl = el;
    }

    componentDidMount() {
    	this.player = new Player(`video-${this.vid}`, this.options);
    	this.player.ready().then(() => {
    		this.onReady();
   		});
    	this.addPlayerEvents();
        window.addEventListener("scroll", this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
        this.removePlayerEvents();
        this.player.destroy();
    }

    handleScroll = e => {
        if (this.state.isPlaying) {
            if (
                this.htmlEl.getBoundingClientRect().bottom < 0 ||
                this.htmlEl.getBoundingClientRect().top >
                    (window.innerHeight ||
                        document.documentElement.clientHeight)
            ) {
                this.pause();
            }
        }
    }

    onReady() {
		Promise.all([this.player.getVideoWidth(), this.player.getVideoHeight()]).then((dimensions) => {
		    this.width = dimensions[0];
		    this.height = dimensions[1];
		});
        this.triggerReady({isVertical: this.width >= this.height});
    }

    addPlayerEvents() {
   		this.player.on('playing', data => {
   			this.setState({isPlaying : true});
   		});
   		this.player.on('pause', data => {
   			this.setState({isPlaying : false});
   		});
    }

    removePlayerEvents() {
    	this.player.off('playing');
    	this.player.off('pause');
    }

    play() {
        this.player.play();
    }

    pause() {
        this.player.pause();
    }

    render() {
        return (
        	<div id={`video-${this.vid}`} ref={this.setRef}></div>
        )
    }
}
