import React from "react";
import { Link } from "react-router-dom";
import { Transition } from "react-transition-group";
import { gsap } from "gsap";

import AnimConf from "../conf/animation";

import Logo from "../components/Logo";
import ContactBar from "../components/ContactBar";
import styles from "./Home.module.css";

function Home(props) {
	return (
		<div className={styles.content}>
			<div className={styles.logo}>
        		<Logo />
	      	</div>
			<Transition
			    timeout={1000}
			    appear
			    in={true}
	           	onEnter={
	              node => {
	                gsap.set(node, { opacity: 0 }); 
	                gsap.to(node, { duration: 1, y: -10, opacity: 1, delay: .7 });
	              }
	            }
               	onExit={
	              node => {
	                gsap.to(node, { duration: .5, y: 0, opacity: 0 });
	              }
	            }
			>
			<div className={styles["poem"]}>
				Si tous les rêves se nourrissaient de la Lune,<br />
				Et n&#39;en laissaient que des quartiers,<br />
				S&#39;il existait un appareil capable de la régénérer,<br />
				On l&#39;appellerait sûrement,<br />
				Lunographe.
	    	</div>
	    	</Transition>
			<Transition
			    timeout={1000}
			    appear
			    in={true}
	           	onEnter={
	              node => {
	                gsap.set(node, { opacity: 0 }); 
	                gsap.to(node, { duration: 1, opacity: 1, delay: 1.2 });
	              }
	            }
               	onExit={
	              node => {
	                gsap.to(node, { duration: 1, opacity: 0 });
	              }
	            }
			>
				<ContactBar />
			</Transition>
		</div>
	);
}

export default Home;
