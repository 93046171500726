import React from "react";
import { Link } from "react-router-dom";
import logo from "../assets/svg/logo.svg";
import styles from "../components/Logo.module.css";

function Logo({ showName = true }) {
	return (
		<div className={styles.container}>
			<label htmlFor="menu-input">
				<img src={logo} className={styles.logo} alt="Logo" />
			</label>
			{showName && <p className={styles["name"]}>Lunographe</p>}
		</div>
	);
}

export default Logo;
