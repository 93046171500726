import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Transition, TransitionGroup } from "react-transition-group";
import { gsap } from "gsap";

import ApiConf from "../conf/backend";

import MoonLoader from "./Loader";
import ButtonRectangle from "./ButtonRectangle";

import styles from "../components/ProjectsNav.module.css";

function ProjectsNav(props) {
  const [projectsTypes, setProjectsTypes] = useState([]);
  const { isPro=false, triggerLoaded } = props;
  const navRef = useRef(null);

  const getProjectsTypesEl = () => {
    return (
      <TransitionGroup>
        {projectsTypes.map((row, index) => (
          <Transition
          key={row.id}
          timeout={1000}
          mountOnEnter
          unmountOnExit
          appear
          in={true}
          addEndListener={(node, done) => {
            gsap.to(node, {
              duration: .5,
              opacity: 1,
              delay: index * 0.5,
              onComplete: done,
            });
          }}
          >
            <Link key={row.id} to={`/projects/type/${row.id}`} className={styles.navlink}><ButtonRectangle key={row.id}>{row.label}</ButtonRectangle></Link>
          </Transition>
        ))}
      </TransitionGroup>
    );
  };

  useEffect(() => {
    const fetchProjectsTypes = isPro => {
      const route = `projects/types?is_pro=${+isPro}`;
      return fetch(`${ApiConf.rootUrl}${route}`)
        .then(response => response.json())
        .then(types => {
          setProjectsTypes(types);
          //setTimeout(triggerLoaded, 1000);
        })
        .catch(e => {
          console.log(e);
        });
    };
    fetchProjectsTypes(isPro);
  }, []);

  return (
    <>
      <div
        id="projects-nav"
        className={styles.container}
        ref={navRef}
      >
        {getProjectsTypesEl()}
      </div>
      {!projectsTypes.length && <MoonLoader />}
    </>
  );
}

export default ProjectsNav;
